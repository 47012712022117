import React from "react";
import { Chrono } from "react-chrono";

const Timeline = () => {
  const items = [
    {
      title: "May 2020",
      cardTitle: "Moons Launch",
      cardDetailedText:
        "Moons were first launched on Rinkeby testnet as part of Reddit's Community Points project",
    },
    {
      title: "June 2020",
      cardTitle: "The First Moon Landing",
      cardDetailedText:
        "Moons were first distributed to users in June 2020. Each user earned 8.8 Moons per karma on r/CryptoCurrency",
    },
    {
      title: "July 2021",
      cardTitle: "Arbitrum Nova Testnet",
      cardDetailedText:
        "Moons are migrated from Rinkeby testnet to the Arbitrum Nova testnet",
    },
    {
      title: "August 2022",
      cardTitle: "Arbitrum Nova Mainnet",
      cardDetailedText:
        "Moons are migrated from Arbitrum Nova testnet to Arbitrum Nova mainnet",
    },
    {
      title: "February 2023",
      cardTitle: "Major Moon Burn",
      cardDetailedText:
        "The bridge from Rinkeby testnet to Arbitrum nova was deprecated. 4.7M Moons were effectively burned in the process.",
    },
    {
      title: "July-August 2023",
      cardTitle: "Major CEX Listings",
      cardDetailedText:
        "Crypto.com and Kraken become the first major exchanges to list Moons for trading",
    },
    {
      title: "October 2023",
      cardTitle: "Sunsetting of Community Points",
      cardDetailedText:
        "Reddit Admins ends their involvement in Moons. They renounced the Moons contract and burned their ~40M Moons",
    },
    {
      title: "March 2024",
      cardTitle: "Arbitrum One",
      cardDetailedText:
        "Moons can now be bridged to Arbitrum One, a much more active chain than Nova",
    },
    {
      title: "Ongoing",
      cardTitle: "Moonrise",
      cardDetailedText:
        "Moderators are working on forming a Moon DAO and restarting distribution to users",
    },
  ];

  return (
    <div style={{ width: "500px", height: "2200px", paddingBottom: "50px" }}>
      <Chrono
        items={items}
        mode="VERTICAL"
        borderLessCards={true}
        toolbarPosition="BOTTOM"
        theme={{
          primary: "#cc3600",
          titleColor: "#cc3600",
          secondary: "",
          cardBgColor: "",
          cardTitleColor: "#c770f0",
          cardDetailsColor: "whitesmoke",
          iconBackgroundColor: "",
          titleColorActive: "#cc3600",
        }}
      />
    </div>
  );
};

export default Timeline;
