import React from "react";
import Typewriter from "typewriter-effect";

function Type() {
  return (
    <Typewriter
      options={{
        strings: [
          "Kraken.com: 81,000 Moons",
          "1Inch.io: 63,000 Moons",
          "CoinGecko: 48,300 Moons",
          "AgoraDesk: 679,000 Moons",
          "Nexo: 68,400 Moons",
          "Cartesi: 37,500 Moons",
          "Polymarket: 10,380 Moons",
          "Manta Network: 8,000 Moons",
          "Bitget: 2,250 Moons",
          "Keystone: 13,500 Moons",
          "stryke.xyz: 8,100 Moons",
        ],
        autoStart: true,
        loop: true,
        deleteSpeed: 0,
        cursor: "",
        delay: 0,
        pauseFor: 3000,
        wrapperClassName: "main-name",
      }}
    />
  );
}

export default Type;
